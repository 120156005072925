$(document).ready(function() {
    $(".get-select").change(function() {
        var href = new URL(window.location.href);
        href.searchParams.set(this.id, this.value);
        window.location.href = href.toString();
    });

    $(".open-hour-status-dialog").click(function() {
        var hourStatusId = $(this).attr('hour-status-id');
        var apiUrl = window.location.protocol + '//' + window.location.host + '/api/hour_status/' + hourStatusId;
        $("#hourStatusDialog .week_hours_status").removeClass("allowed denied");
        $("#hourStatusDialog .week_hours_status").text("");
        $("#hourStatusDialog .week").text("");

        $.ajax({
            url: apiUrl,
            type: 'GET',
            dataType: 'json', // added data type
            success: function(res) {

                
                $("#hourStatusDialog .modal-body").text(res.description);
                $("#hourStatusDialog .week").text("Week " + res.week);
                $("#hourStatusDialog .modal-title").text(res.user.name);

                switch(res.status) {
                    case "Allowed":
                        $("#hourStatusDialog .week_hours_status").addClass("allowed");
                        $("#hourStatusDialog .week_hours_status").text("Goedgekeurd");
                    break;

                    case "Denied":
                        $("#hourStatusDialog .week_hours_status").addClass("denied");
                        $("#hourStatusDialog .week_hours_status").text("Afgekeurd");
                    break

                    default:
                        $("#hourStatusDialog .week_hours_status").text("In afwachting");
                }
            }
        });

    });

    $(".change-hour-status-dialog").click(function() {
        var hourStatusUser = $(this).attr('hour-status-user');
        
        $("#changeHourStatusDialog input#user_id").val(hourStatusUser);

    });
});

