$("#user_id").change(function() {

    // Get Role from selected User (medewerker)
	var role = $(this).find(':selected').attr('role');

    // Make sure everythin is selectedable.
	$("#type_id option").attr("disabled",false);


    // When group 3 is the role.
	if(role == "group_3") {

        // Disable everything.
		$("#type_id option").attr("disabled",true);

        // Enable only Arbeid
		$('#type_id option[value="1"]').attr("disabled",false);

        // Select only Arbeid.
		$('#type_id option[value="1"]').attr("selected","selected");

	}

    // Make sure the first one is not selected able. (selecteer type)
    $("#type_id option").first().attr("disabled",true);

	
});

/*
$("td").each(function() {
	var innerhtml = $(this).html().trim();
	
	if(innerhtml == "0,00") {
		$(this).html(" ");
	}
	
});
*/